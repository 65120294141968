import '../index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faSquareTwitter } from '@fortawesome/free-brands-svg-icons';


function Home() {
    return (
        <section className="hero h-lvh flex justify-center items-center h-screen">
            <div id="video-background" className="absolute top-0 left-0 w-full h-full z-[-1]">
                <svg className="m-auto bg-navy block relative z-10 w-full h-full" preserveAspectRatio="xMidYMid" viewBox="0 0 1511 762">
                    <g transform="translate(755.5,381) scale(1,1) translate(-755.5,-381)"><defs><filter id="blur-bex1fajljgu-1" x="-1" y="-1" width="3" height="3">
                        <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    </filter><filter id="blur-bex1fajljgu-2" x="-1" y="-1" width="3" height="3">
                        <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    </filter><filter id="blur-bex1fajljgu-3" x="-1" y="-1" width="3" height="3">
                        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                    </filter></defs><circle cx="1282.7740104396198" cy="0" transform="translate(0 304.25756323497916)" r="5.5803669414627715" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-2.3421973308986117s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 767.5803669414628;0 -5.5803669414627715"></animateTransform>
                    </circle><circle cx="658.9047165838788" cy="0" transform="translate(0 402.6438413340793)" r="5.174164916331588" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-2.5626304341098165s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 767.1741649163316;0 -5.174164916331588"></animateTransform>
                    </circle><circle cx="1458.8037636434342" cy="0" transform="translate(0 738.4902336614751)" r="4.756289390033477" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-6.56428733023149s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 766.7562893900334;0 -4.756289390033477"></animateTransform>
                    </circle><circle cx="302.6335212469662" cy="0" transform="translate(0 504.45906272472564)" r="3.4638379535811055" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-2.106769072257708s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 765.4638379535811;0 -3.4638379535811055"></animateTransform>
                    </circle><circle cx="630.4847013103526" cy="0" transform="translate(0 424.2304650978169)" r="3.7525908768961433" fill="rgba(13, 112, 242, 0.7482056451612904)" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-0.20008233322666374s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 765.7525908768962;0 -3.7525908768961433"></animateTransform>
                    </circle><circle cx="1035.5208297770405" cy="0" transform="translate(0 129.32064595655194)" r="6.051389520641608" fill="rgba(13, 112, 242, 0.7482056451612904)" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-4.266318279007871s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 768.0513895206416;0 -6.051389520641608"></animateTransform>
                    </circle><circle cx="826.5175771589293" cy="0" transform="translate(0 276.78270457862743)" r="4.381739190849775" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-2.606182512571683s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 766.3817391908498;0 -4.381739190849775"></animateTransform>
                    </circle><circle cx="1140.5123601955534" cy="0" transform="translate(0 384.28014260824057)" r="3.6367588241975506" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-3.5511283930646367s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 765.6367588241975;0 -3.6367588241975506"></animateTransform>
                    </circle><circle cx="175.05280285856148" cy="0" transform="translate(0 256.18203846366964)" r="3.811485541188038" fill="#f943c9" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-3.4670115567679796s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 765.8114855411881;0 -3.811485541188038"></animateTransform>
                    </circle><circle cx="1325.5952177640474" cy="0" transform="translate(0 410.16529115501004)" r="4.684874307018465" fill="#f943c9" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-5.724257777623988s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 766.6848743070185;0 -4.684874307018465"></animateTransform>
                    </circle><circle cx="1314.6481856140776" cy="0" transform="translate(0 630.11527809919)" r="6.394532317960151" fill="rgba(13, 112, 242, 0.7482056451612904)" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-0.8439163951724509s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 768.3945323179602;0 -6.394532317960151"></animateTransform>
                    </circle><circle cx="812.9552668385745" cy="0" transform="translate(0 227.03538420247602)" r="2.495204556497324" fill="#f943c9" opacity="0.36000000000000004">
                        <animateTransform attributeName="transform" type="translate" begin="-4.800277678457008s" dur="14.925373134328357s" repeatCount="indefinite" keyTimes="0;1" values="0 764.4952045564974;0 -2.495204556497324"></animateTransform>
                    </circle><circle cx="1087.9043532419016" cy="0" transform="translate(0 728.1922262883551)" r="5.717123595068715" fill="#f943c9" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-3.1568210218695754s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 767.7171235950688;0 -5.717123595068715"></animateTransform>
                    </circle><circle cx="214.20741482185977" cy="0" transform="translate(0 609.1405344250747)" r="5.613346032760342" fill="#f943c9" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-3.2989902524416177s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 767.6133460327603;0 -5.613346032760342"></animateTransform>
                    </circle><circle cx="507.94014490062875" cy="0" transform="translate(0 393.71713587072827)" r="2.3582601819117213" fill="#f943c9" opacity="0.36000000000000004">
                        <animateTransform attributeName="transform" type="translate" begin="-7.449144822809965s" dur="14.925373134328357s" repeatCount="indefinite" keyTimes="0;1" values="0 764.3582601819118;0 -2.3582601819117213"></animateTransform>
                    </circle><circle cx="802.3808793735105" cy="0" transform="translate(0 208.29342752505642)" r="5.1541981912714725" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-3.3720994345281357s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 767.1541981912715;0 -5.1541981912714725"></animateTransform>
                    </circle><circle cx="526.3929400580386" cy="0" transform="translate(0 179.3507672222225)" r="4.433435468070994" fill="#f943c9" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-7.365984657243853s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 766.433435468071;0 -4.433435468070994"></animateTransform>
                    </circle><circle cx="1138.029621360397" cy="0" transform="translate(0 760.5394170362383)" r="6.335131108772667" fill="#f943c9" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-0.16511505694773984s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 768.3351311087727;0 -6.335131108772667"></animateTransform>
                    </circle><circle cx="13.734888386457207" cy="0" transform="translate(0 439.07162585840103)" r="3.1745079571617962" fill="rgba(13, 112, 242, 0.7482056451612904)" opacity="0.36000000000000004">
                        <animateTransform attributeName="transform" type="translate" begin="-6.6354608813523015s" dur="14.925373134328357s" repeatCount="indefinite" keyTimes="0;1" values="0 765.1745079571618;0 -3.1745079571617962"></animateTransform>
                    </circle><circle cx="215.5553634132661" cy="0" transform="translate(0 431.3709132823968)" r="4.4576060697982145" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-7.1483362570036855s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 766.4576060697982;0 -4.4576060697982145"></animateTransform>
                    </circle><circle cx="629.4745257167817" cy="0" transform="translate(0 284.6511864265068)" r="5.447323278063469" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-1.9815364183493451s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 767.4473232780634;0 -5.447323278063469"></animateTransform>
                    </circle><circle cx="508.82816354089255" cy="0" transform="translate(0 594.4573586882798)" r="3.4609530034361367" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-1.1341942253749886s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 765.4609530034361;0 -3.4609530034361367"></animateTransform>
                    </circle><circle cx="872.5148830771302" cy="0" transform="translate(0 32.74102593432243)" r="6.169361843996388" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-4.233581262170584s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 768.1693618439964;0 -6.169361843996388"></animateTransform>
                    </circle><circle cx="1225.3078881829563" cy="0" transform="translate(0 642.9196548564358)" r="5.224379925006404" fill="#f943c9" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-0.513671026499024s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 767.2243799250064;0 -5.224379925006404"></animateTransform>
                    </circle><circle cx="1014.5984882003742" cy="0" transform="translate(0 18.79451689958531)" r="3.9402386480154235" fill="rgba(13, 112, 242, 0.7482056451612904)" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-1.1362797983000459s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 765.9402386480155;0 -3.9402386480154235"></animateTransform>
                    </circle><circle cx="779.1048821387249" cy="0" transform="translate(0 533.3577778966646)" r="6.1230140879043695" fill="#f943c9" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-1.2061253593984025s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 768.1230140879044;0 -6.1230140879043695"></animateTransform>
                    </circle><circle cx="752.551226241304" cy="0" transform="translate(0 678.9384595261929)" r="5.828816830635143" fill="rgba(13, 112, 242, 0.7482056451612904)" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-3.6675600530387302s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 767.8288168306351;0 -5.828816830635143"></animateTransform>
                    </circle><circle cx="6.1061534971322855" cy="0" transform="translate(0 302.546660320812)" r="3.317391635678503" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.36000000000000004">
                        <animateTransform attributeName="transform" type="translate" begin="-5.092935057402054s" dur="14.925373134328357s" repeatCount="indefinite" keyTimes="0;1" values="0 765.3173916356785;0 -3.317391635678503"></animateTransform>
                    </circle><circle cx="917.5295175627978" cy="0" transform="translate(0 49.53283410140963)" r="4.593403587016363" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-2.692262601155677s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 766.5934035870164;0 -4.593403587016363"></animateTransform>
                    </circle><circle cx="39.88222846697924" cy="0" transform="translate(0 676.1707787621934)" r="3.5417176751786705" fill="#f943c9" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-6.827159016804176s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 765.5417176751787;0 -3.5417176751786705"></animateTransform>
                    </circle><circle cx="1374.3748742876235" cy="0" transform="translate(0 353.90723794463787)" r="2.2143571615096564" fill="rgba(13, 112, 242, 0.7482056451612904)" opacity="0.36000000000000004">
                        <animateTransform attributeName="transform" type="translate" begin="-10.362398911370931s" dur="14.925373134328357s" repeatCount="indefinite" keyTimes="0;1" values="0 764.2143571615096;0 -2.2143571615096564"></animateTransform>
                    </circle><circle cx="370.23267928135766" cy="0" transform="translate(0 759.8774546841851)" r="2.375077229099243" fill="#f943c9" opacity="0.36000000000000004">
                        <animateTransform attributeName="transform" type="translate" begin="-6.8836698391552655s" dur="14.925373134328357s" repeatCount="indefinite" keyTimes="0;1" values="0 764.3750772290992;0 -2.375077229099243"></animateTransform>
                    </circle><circle cx="260.1804134841886" cy="0" transform="translate(0 668.6958217913996)" r="3.921569775614315" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-6.724706552650531s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 765.9215697756143;0 -3.921569775614315"></animateTransform>
                    </circle><circle cx="484.48291430771144" cy="0" transform="translate(0 753.4964327124919)" r="1.9457869057310804" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.36000000000000004">
                        <animateTransform attributeName="transform" type="translate" begin="-4.3627222838381154s" dur="14.925373134328357s" repeatCount="indefinite" keyTimes="0;1" values="0 763.9457869057311;0 -1.9457869057310804"></animateTransform>
                    </circle><circle cx="535.253502940073" cy="0" transform="translate(0 613.5640519376667)" r="3.706115086058692" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-3.3331387399806287s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 765.7061150860587;0 -3.706115086058692"></animateTransform>
                    </circle><circle cx="878.8842998023326" cy="0" transform="translate(0 77.9623422944027)" r="5.340416702479871" fill="rgba(13, 112, 242, 0.7482056451612904)" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-3.674120722956106s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 767.3404167024798;0 -5.340416702479871"></animateTransform>
                    </circle><circle cx="1040.5126713937716" cy="0" transform="translate(0 674.5622953605207)" r="5.0162632235094335" fill="#f943c9" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-0.17902533154542502s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 767.0162632235094;0 -5.0162632235094335"></animateTransform>
                    </circle><circle cx="1361.386188944057" cy="0" transform="translate(0 355.27056132050467)" r="5.792951672835312" fill="#f943c9" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-1.9683511355244472s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 767.7929516728353;0 -5.792951672835312"></animateTransform>
                    </circle><circle cx="1258.9906972345534" cy="0" transform="translate(0 229.18352009822)" r="6.213640936217294" fill="#f943c9" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-2.2211130802336916s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 768.2136409362173;0 -6.213640936217294"></animateTransform>
                    </circle><circle cx="1479.0597699869968" cy="0" transform="translate(0 97.04262880054794)" r="2.179914843433297" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.36000000000000004">
                        <animateTransform attributeName="transform" type="translate" begin="-13.15542601765187s" dur="14.925373134328357s" repeatCount="indefinite" keyTimes="0;1" values="0 764.1799148434333;0 -2.179914843433297"></animateTransform>
                    </circle><circle cx="134.00678242926435" cy="0" transform="translate(0 430.12904400202785)" r="1.9089232056291565" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.36000000000000004">
                        <animateTransform attributeName="transform" type="translate" begin="-10.127977972913643s" dur="14.925373134328357s" repeatCount="indefinite" keyTimes="0;1" values="0 763.9089232056292;0 -1.9089232056291565"></animateTransform>
                    </circle><circle cx="258.2745521509912" cy="0" transform="translate(0 79.94558225719244)" r="4.684077247854642" fill="rgba(13, 112, 242, 0.7482056451612904)" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-1.777458262814868s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 766.6840772478546;0 -4.684077247854642"></animateTransform>
                    </circle><circle cx="578.5521623457536" cy="0" transform="translate(0 166.1886566371487)" r="3.688101608269017" fill="rgba(13, 112, 242, 0.7482056451612904)" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-1.4330543557813031s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 765.688101608269;0 -3.688101608269017"></animateTransform>
                    </circle><circle cx="1154.837395449379" cy="0" transform="translate(0 259.13287832551094)" r="5.467607075224358" fill="#f943c9" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-3.5334422564780996s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 767.4676070752244;0 -5.467607075224358"></animateTransform>
                    </circle><circle cx="1138.4175468652088" cy="0" transform="translate(0 589.5396191343818)" r="3.498406505473284" fill="rgba(13, 112, 242, 0.7482056451612904)" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-2.794491618863752s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 765.4984065054733;0 -3.498406505473284"></animateTransform>
                    </circle><circle cx="2.91233237898787" cy="0" transform="translate(0 279.58190458134504)" r="2.7003140454364667" fill="rgba(13, 112, 242, 0.7482056451612904)" opacity="0.36000000000000004">
                        <animateTransform attributeName="transform" type="translate" begin="-4.00655282269925s" dur="14.925373134328357s" repeatCount="indefinite" keyTimes="0;1" values="0 764.7003140454365;0 -2.7003140454364667"></animateTransform>
                    </circle><circle cx="547.0380790721329" cy="0" transform="translate(0 683.5420425527577)" r="2.0118487829943343" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.36000000000000004">
                        <animateTransform attributeName="transform" type="translate" begin="-0.1246273399061951s" dur="14.925373134328357s" repeatCount="indefinite" keyTimes="0;1" values="0 764.0118487829943;0 -2.0118487829943343"></animateTransform>
                    </circle><circle cx="926.1134592406748" cy="0" transform="translate(0 370.108723991938)" r="5.4672109090820555" fill="rgba(13, 112, 242, 0.7482056451612904)" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-1.94917147937571s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 767.467210909082;0 -5.4672109090820555"></animateTransform>
                    </circle><circle cx="149.3774934812826" cy="0" transform="translate(0 118.11403598534119)" r="4.244083549417339" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-2.2730522407023734s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 766.2440835494174;0 -4.244083549417339"></animateTransform>
                    </circle><circle cx="1013.8272028538769" cy="0" transform="translate(0 167.6334756755774)" r="2.466086067040535" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.36000000000000004">
                        <animateTransform attributeName="transform" type="translate" begin="-0.651505838048599s" dur="14.925373134328357s" repeatCount="indefinite" keyTimes="0;1" values="0 764.4660860670406;0 -2.466086067040535"></animateTransform>
                    </circle><circle cx="325.06189612426664" cy="0" transform="translate(0 215.5026524434249)" r="2.62608837589943" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.36000000000000004">
                        <animateTransform attributeName="transform" type="translate" begin="-6.203035463971031s" dur="14.925373134328357s" repeatCount="indefinite" keyTimes="0;1" values="0 764.6260883758995;0 -2.62608837589943"></animateTransform>
                    </circle><circle cx="615.5405278012513" cy="0" transform="translate(0 480.06725523644246)" r="5.216442849136771" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-3.340720102068969s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 767.2164428491368;0 -5.216442849136771"></animateTransform>
                    </circle><circle cx="721.2811083800283" cy="0" transform="translate(0 519.8212846147503)" r="3.5948990120135016" fill="rgba(13, 112, 242, 0.7482056451612904)" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-5.84383562200402s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 765.5948990120135;0 -3.5948990120135016"></animateTransform>
                    </circle><circle cx="339.7833910677295" cy="0" transform="translate(0 63.22448311341893)" r="6.287622899218594" fill="#f943c9" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-2.8477592375575673s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 768.2876228992186;0 -6.287622899218594"></animateTransform>
                    </circle><circle cx="616.3907979348537" cy="0" transform="translate(0 462.476444188453)" r="6.387930563509522" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-0.022198524413994346s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 768.3879305635095;0 -6.387930563509522"></animateTransform>
                    </circle><circle cx="948.64267413772" cy="0" transform="translate(0 444.4553469404244)" r="3.6481579277596228" fill="rgba(13, 112, 242, 0.7482056451612904)" opacity="0.63">
                        <animateTransform attributeName="transform" type="translate" begin="-1.2405048609592164s" dur="7.462686567164178s" repeatCount="indefinite" keyTimes="0;1" values="0 765.6481579277596;0 -3.6481579277596228"></animateTransform>
                    </circle><circle cx="538.2339143808678" cy="0" transform="translate(0 5.979081606041358)" r="1.8653693623269683" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.36000000000000004">
                        <animateTransform attributeName="transform" type="translate" begin="-5.432081643471735s" dur="14.925373134328357s" repeatCount="indefinite" keyTimes="0;1" values="0 763.865369362327;0 -1.8653693623269683"></animateTransform>
                    </circle><circle cx="1446.2669691520705" cy="0" transform="translate(0 160.3516350706569)" r="5.823183337000058" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-2.690328354961852s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 767.823183337;0 -5.823183337000058"></animateTransform>
                    </circle><circle cx="990.9505731722867" cy="0" transform="translate(0 491.2211267556676)" r="6.506862767991547" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-1.45063251157881s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 768.5068627679915;0 -6.506862767991547"></animateTransform>
                    </circle><circle cx="409.45063930891996" cy="0" transform="translate(0 164.953841314249)" r="1.8166994003006225" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.36000000000000004">
                        <animateTransform attributeName="transform" type="translate" begin="-6.522376728202377s" dur="14.925373134328357s" repeatCount="indefinite" keyTimes="0;1" values="0 763.8166994003006;0 -1.8166994003006225"></animateTransform>
                    </circle><circle cx="505.4718532451483" cy="0" transform="translate(0 729.3709677381975)" r="5.199673368426927" fill="#f943c9" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-1.9728323603603488s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 767.1996733684269;0 -5.199673368426927"></animateTransform>
                    </circle><circle cx="1462.5119314263156" cy="0" transform="translate(0 636.4777981494583)" r="5.999071125337913" fill="#f943c9" opacity="0.7200000000000001">
                        <animateTransform attributeName="transform" type="translate" begin="-4.208417492754186s" dur="4.975124378109452s" repeatCount="indefinite" keyTimes="0;1" values="0 767.9990711253379;0 -5.999071125337913"></animateTransform>
                    </circle><circle cx="352.9710055092514" cy="0" transform="translate(0 137.81569770611148)" r="1.9391417541309641" fill="#f943c9" opacity="0.36000000000000004">
                        <animateTransform attributeName="transform" type="translate" begin="-9.388501284333307s" dur="14.925373134328357s" repeatCount="indefinite" keyTimes="0;1" values="0 763.939141754131;0 -1.9391417541309641"></animateTransform>
                    </circle><circle cx="197.50503471280518" cy="0" transform="translate(0 397.6519277693356)" r="2.821399680409187" fill="rgba(249, 67, 201, 0.3623991935483871)" opacity="0.36000000000000004">
                        <animateTransform attributeName="transform" type="translate" begin="-14.5595595669477s" dur="14.925373134328357s" repeatCount="indefinite" keyTimes="0;1" values="0 764.8213996804092;0 -2.821399680409187"></animateTransform>
                    </circle><circle cx="469.9006596613598" cy="0" transform="translate(0 336.7621686407007)" r="2.200236950359302" fill="#f943c9" opacity="0.36000000000000004">
                        <animateTransform attributeName="transform" type="translate" begin="-0.8409404319784785s" dur="14.925373134328357s" repeatCount="indefinite" keyTimes="0;1" values="0 764.2002369503593;0 -2.200236950359302"></animateTransform>
                    </circle></g>
                </svg>
            </div>
            <div className="home-container text-white flex flex-col items-center justify-center space-y-6 mt-10">
                <h1 id="name" className="fade-in text-6xl font-inter font-light font-paragraph mb-5 tracking-wide">Hi there, I'm <span id="gradient" className="font-bold font-heading">Sabrina (Sabfry) </span>👋🏻</h1>
                <p id="bio" className="slide-in-first font-light font-paragraph text-2xl tracking-wide ">A data engineer turned software developer living in Denver.</p>
                <p id="scroll" className="slide-in-first font-light font-paragraph text-2xl tracking-wide"><span id="gradient"> Feel free to scroll down to see some of my latest work!</span></p>
                <div className="mt-6">
                    <a href="/SEResume_sabrina_freifeld.docx" className="button-resume tracking-wide mt-8" target="_blank" role="button">Download Resume</a>
                </div>
                <div className="icon-container flex space-x-4">
                    <a href="https://www.linkedin.com/in/sabrinafreifeld/" target="_blank">
                        <FontAwesomeIcon icon={faLinkedin} size="3x" className='fa-brands' />
                    </a>
                    <a href="https://github.com/sfreifeld" target="_blank">
                        <FontAwesomeIcon icon={faGithub} size="3x" className='fa-brands' />
                    </a>
                    <a href="https://twitter.com/sabfry" target="_blank">
                        <FontAwesomeIcon icon={faSquareTwitter} size="3x" className='fa-brands' />
                    </a>
                </div>
            </div>
        </section>
    )
}

export default Home